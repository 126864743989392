<template>
  <v-container>
      <h1>Gruppenstunden</h1>
      <v-col cols=12>
          <v-card v-for="gruppenstunde in data" :key="gruppenstunde._id" flat outlined>
              <v-card-title>{{ gruppenstunde.titel }}</v-card-title>
              <v-card-text>
                  <v-row cols="12">
                      <v-col cols="4">
                        <v-text-field
                        label="Gruppenstunde"
                        v-model="gruppenstunde.titel"
                        outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                        label="Beschreibung"
                        v-model="gruppenstunde.beschreibung"
                        outlined
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                        label="Zeit"
                        v-model="gruppenstunde.zeit"
                        outlined
                        >
                        </v-text-field>
                      </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row cols="12" class="mt-2">
                      <v-col cols="6">
                          <v-btn 
                          class="col-12"
                          large
                          color="primary"
                          @click="SaveGruppenstunde(gruppenstunde.new ? 'post' : 'patch', gruppenstunde)"
                          >
                            Speichern
                          </v-btn>
                      </v-col>
                      <v-col cols="6">
                          <v-btn 
                          class="col-12"
                          large
                          color="red"
                          @click="RemoveGruppenstunde(gruppenstunde)"
                          >
                            Löschen
                          </v-btn>
                      </v-col>
                  </v-row>
              </v-card-text>
          </v-card>
      </v-col>
      <v-col cols="12">
        <v-btn class="col-12" color="secondary" large @click="AddGruppenstunde()">Gruppenstunde hinzufügen</v-btn>
      </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data:() => {
        return {
            data: []
        }
    },
    methods: {
        AddGruppenstunde() {
            this.data.push({
                titel: "",
                beschreibung: "",
                zeit: "",
                new: true
            })
        },
        SaveGruppenstunde(method, index) {
            if(method === 'patch') {
                axios
                .patch('https://kjg-api.rezept-zettel.de/api/gruppenstunden/' + index._id, index)
                .then(res => {
                console.log(res.data)
                })
                .catch((e) => {
                    console.log(e)
                })
            } else {
                axios
                .post('https://kjg-api.rezept-zettel.de/api/gruppenstunden', index)
                .then(res => {
                    console.log(res.data)
                })
                .catch((e) => {
                    console.log(e)
                })
            }
        },
        RemoveGruppenstunde(index) {
            this.data.splice(this.data.indexOf(index), 1);
            axios
            .delete('https://kjg-api.rezept-zettel.de/api/gruppenstunden/' + index._id)
            .then(res => {
                console.log(res.data)
            })
            .catch((e) => {
                console.log(e)
            })
        },
        fetchData() {
            axios
            .get('https://kjg-api.rezept-zettel.de/api/gruppenstunden')
            .then(res => {
                this.data = res.data
            })
            .catch((e) => {
                console.log(e)
            })
        }
    },
    created() {
        this.fetchData()
    }
}
</script>

<style>

</style>